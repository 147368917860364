import { gql } from '@apollo/client';

export const GET_CURRENT_ADD_ONS = gql`
  query getCurrentAddOns($input: GetCurrentAddOnsInput) {
    getCurrentAddOns(input: $input) {
      name
      description
      state
      kind
      price
      discountName
      discountEndDate
      discountAmount
      priceWithDiscount
      productInstanceId
      isBundle
    }
  }
`;

const CHARACTERISTICS_FRAGMENT = gql`
  fragment CharacteristicsFragment on Characteristic {
    name
    value
  }
`;

const PRICES_FRAGMENT = gql`
  fragment PricesFragment on ProductTypePrice {
    amount {
      value
    }
  }
`;

const MARKETING_COPY_FRAGMENT = gql`
  fragment MarketingCopyFragment on MarketingCopy {
    ui_behaviors {
      characteristics {
        ...CharacteristicsFragment
      }
    }
    translations {
      language
      characteristics {
        ...CharacteristicsFragment
      }
    }
  }
  ${CHARACTERISTICS_FRAGMENT}
`;

const DISCOUNTS_FRAGMENT = gql`
  fragment DiscountsFragment on ProductTypeDiscount {
    name
    duration
    amount {
      value
      currency {
        minor_units
        numeric_code
        major_unit_symbol
        alphabetic_code
      }
    }
  }
`;

export const GET_ADDON_OFFERS = gql`
  query getAddOnOffers($showGroupedEasyCare: Boolean) {
    getAddOnOffers(showGroupedEasyCare: $showGroupedEasyCare) {
      id
      kind
      name
      description
      is_grouped_product
      characteristics {
        ...CharacteristicsFragment
      }
      discounts {
        total_discounts {
          ...DiscountsFragment
        }
        itemized_discounts {
          ...DiscountsFragment
        }
      }
      offer_id
      marketing_copy {
        ...MarketingCopyFragment
      }
      prices {
        ...PricesFragment
      }
      package_types {
        id
        product_types {
          id
          name
          kind
          marketing_copy {
            ...MarketingCopyFragment
          }
          prices {
            ...PricesFragment
          }
        }
      }
    }
  }

  ${CHARACTERISTICS_FRAGMENT}
  ${PRICES_FRAGMENT}
  ${MARKETING_COPY_FRAGMENT}
  ${DISCOUNTS_FRAGMENT}
`;

export const PURCHASE_ADD_ON = gql`
  mutation purchaseAddOn($input: PurchaseAddOnInput!) {
    purchaseAddOn(input: $input) {
      success
      message
      orderId
    }
  }
`;

export const GET_AVAILABLE_PHONE_NUMBERS = gql`
  query getAvailableTelephoneNumbers(
    $input: GetAvailableTelephoneNumbersInput!
  ) {
    getAvailableTelephoneNumbers(input: $input) {
      phoneNumber
      prefix
    }
  }
`;

export const RESERVE_PHONE_NUMBER = gql`
  mutation reserveTelephoneNumber($input: ReserveTelephoneNumberInput!) {
    reserveTelephoneNumber(input: $input) {
      reservedPhoneNumber
      reservedTime
      success
    }
  }
`;

export const CONFIGURE_ADDON = gql`
  mutation configureAddon($input: ConfigureAddonInput!) {
    configureAddon(input: $input) {
      success
      message
    }
  }
`;

export const GET_SCRUBBED_ADDRESS = gql`
  query getScrubbedAddress($address: GetScrubbedAddressInput!) {
    getScrubbedAddress(address: $address) {
      addressLines
      municipality
      region
      country
      postalCode
      countryCode
      processStatus
      recommendation
    }
  }
`;

export const SEND_SURVEY_RESULTS_TO_QUALTRICS = gql`
  mutation sendSurveyResultsToQualtrics(
    $input: SendSurveyResultsToQualtricsInput!
  ) {
    sendSurveyResultsToQualtrics(input: $input) {
      success
    }
  }
`;

export const REMOVE_ADDON = gql`
  mutation removeAddOn($input: RemoveAddOnInput) {
    removeAddOn(input: $input) {
      success
      message
      orderId
    }
  }
`;
